import React from "react";
import SubPageBase from "../components/SubPageBase";
import img from "../images/department.png";
import { Typography } from "@material-ui/core";
import { Link } from "gatsby";

const Index = () => (
  <SubPageBase title="YASAL BİLDİRİMLER" img={img}>

    <Typography variant="h5" gutterBottom>KURUMSAL KİMLİĞİMİZ</Typography>
    <Typography variant="body1" gutterBottom>
      Kariyer.decathlon.com.tr adlı web sitemiz Turksport Spor Ürünleri San. ve Tic. Ltd. Şti
      tarafından düzenlenmektedir. Genel merkezi, Osmaniye Mahallesi Çobançeşme
      Koşuyolu Blv. Marmara Forum Sit. A Blok Apt. No:3/Z099 Bakırköy İstanbul mukim
      Turksport Spor Ürünleri San. ve Tic. Ltd. Şti. 698385 sicil numarası ile İstanbul Ticaret
      Odasına kayıtlıdır.
    </Typography>


    <Typography variant="h5" gutterBottom>İLETİŞİM</Typography>
    <Typography variant="body1" gutterBottom>
      <b>Telefon:</b> 0212 437 37 34 (sabit hat, standart ücretlendirme ile hafta içi (Pazartesi-
      Cuma) 09:00-18:30 saatleri arasında hizmet vermektedir.) numaralı telefon
      numarasından;<br/>
      <b>Adres:</b> Osmaniye Mahallesi Çobançeşme Koşuyolu Blv. Marmara Forum Sit. A Blok
      Apt. No:3/Z099 Bakırköy/İstanbul adresinden posta yoluyla bize ulaşabilirsiniz.
    </Typography>

    <Typography variant="h5" gutterBottom>YER-HİZMET SAĞLAYICI</Typography>
    <Typography variant="body1" gutterBottom>
      Turksport Spor Ürünleri San. ve Tic. Ltd. Şti
    </Typography>

    <Typography variant="h5" gutterBottom>KİŞİ BİLGİLERİNİN KORUNMASI VE TAHSİS EDİLMESİ</Typography>
    <Typography variant="body1" gutterBottom>
      Web sitemiz aracılığıyla toplanan kişisel bilgileriniz, yalnızca Turksport Spor Ürünleri
      San. ve Tic. Ltd. Şti.’ye tahsis edilmiş olup, gizliliği korunmaktadır. Kişisel bilgilerinizin
      korunmasına ilişkin politikamıza ulaşmak için <Link to="/aday-aydinlatma-metni/">tıklayınız.</Link>
    </Typography>
  </SubPageBase>
);

export default Index;
